@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
    --background-color: #4e355c;
    --text-color: #ffffff;
    --accent: #f11591;
    --ui-color: #f11591;
    --light-text-color: #ffffff;
    --light-background-color: #4e355c;
    --mascot: #6DBAD5;
    
    --default-padding: 40px;

    --font-family-mono: 'IBM Plex Mono', monospace;
}

.light-mode {
    --background-color: #ffd5df;
    --text-color: #4e355c;
    --ui-color: #f11591;
}

* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

head {
    display: none;
}

body {
    font-family: var(--font-family-mono);
    background-color: var(--background-color);
    color: var(--text-color);
}

.main-wrapper {
    display:block;
}

.main-content {
    width: 100%;
    height: 100svh;
    max-height: 100svh;
    min-height: 100svh;
    flex-direction: column;
    justify-content: space-between;
    padding: 2.5rem;
    display: flex;
}

.content-grid {
    grid-column-gap: 3rem;
    grid-row-gap: 0px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-content: start;
    align-items: start;
    display: grid;
}

/* NAV */

.nav, .nav-menu-container{
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    padding: 0 80px;
    background-color: var(--ui-color);
}

.light-mode .nav {
    color: var(--light-text-color);
    background-color: var(--light-background-color);
}

.light-mode .nav-menu-container {
    color: var(--light-text-color);
    background-color: var(--light-background-color);
}

.nav-header {
    display: grid;
    align-items: center;
    min-height: 40px;
}

.nav-header-title {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
}

.nav-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
}

.nav-menu-item {
    margin-right: 80px;
    font-size: 1.25rem;
    font-weight: 600;
}

.nav-menu-selected {
    color: var(--text-color);
    background-color: var(--background-color);
}

.nav-menu-item:hover {
    color: var(--text-color);
    background-color: var(--background-color);
    cursor: pointer;
}

a, a:visited {
    color: var(--accent);
}

a:hover {
    color: var(--light-text-color);
}

/* TERMINAL */
.terminal {
    position: relative;
    border-color: var(--ui-color);
    border-style: none solid;
    border-width: 0 0.5rem;
    line-height: 1.25rem;
    height: 100%;
    padding: 80px;
    overflow: hidden;
}

.light-mode .terminal {
    border-color: var(--light-background-color);
}

.terminal-panel {
    color: var(--text-color);
    max-width: 70%;
    overflow-y: auto;
    height: 100%;
}

pre {
    margin: 0;
    line-height: 1.5rem;
    white-space: pre;
    font-family: var(--font-family-mono);
}

.terminal-logo, .terminal-version {
    font-weight: 600;
    font-size: 1rem;
    color: var(--accent);
}

.terminal-logo pre {
    line-height: 1.25rem;
}

.terminal-intro {
    overflow-y: hidden;
    white-space: pre-wrap;
}

.terminal-intro pre {
    white-space: pre-wrap;
}

.terminal-content {
}

.terminal-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 600;
}

.prompt {
    white-space: nowrap;
}

.prompt-tilde {
    color: var(--accent);
}

.commands {
    color: var(--accent);
}

input[type="text"] {
    flex: 1;
    font-family: var(--font-family-mono);
    caret-color: var(--text-color);
    font-size: 1rem;
    line-height: 1.25rem;
    margin-left: -3px;
    background: transparent;
    border: none;
    color: inherit;
    outline: none;
}

.terminal-window {
    white-space: pre-wrap;
    overflow-y: auto;
    max-height: 80vh;
}

.output-line {
    
}

.input-line {
    display: flex;
    align-items: center;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.modal-content {
    max-width: calc(120 * 8px);
    max-height: calc(66 * 10px);
    background-color: var(--background-color);
    padding: .5rem;
    border-color: var(--accent);
    border-width: 0.25rem;
    border-style: solid;
}

.light-mode .modal-content {
    border-color: var(--light-background-color);
}

.modal-content pre {
    font-size: 8px;
    line-height: 8.8px;
    color: var(--light-text-color);
}

.light-mode .modal-content pre {
    color: var(--accent);
}

/* FOOTER */
.footer {
    color: var(--text-color);
    padding: 0 80px;
    background-color: var(--ui-color);
}

.light-mode .footer {
    color: var(--light-text-color);
    background-color: var(--light-background-color);
}

.footer-copyright {
    display: grid;
    align-items: center;
    min-height: 40px;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
}

.nodes-logo {
    position: relative;
    width: 16px;
    top: 2px;
}

@media (max-width: 1330px) {
    .terminal-panel {
        max-width: 100%;
    }
}

@media (max-width: 1280px) {
    .nav, .nav-menu-container {
        padding: 0 60px;
    }

    .nav-menu-item {
        margin-right: 60px;
    }
}

@media (max-width: 1100px) {
    .nav, .nav-menu-container {
        padding: 0 40px;
    }

    .nav-menu-item {
        margin-right: 40px;
    }
}

@media (max-width: 1000px) {
    .nav, .nav-menu-container {
        padding: 0 30px;
    }

    .nav-menu-item {
        margin-right: 30px;
    }

    .main-content {
        padding: 20px;
    }

    .terminal {
        padding: 40px;
    }
}

@media (max-width: 888px) {
    .nav, .nav-menu-container {
        padding: 0 20px;
    }

    .nav-menu-item {
        margin-right: 20px;
    }

    .main-content {
        padding: 20px;
    }

    .terminal {
        padding: 30px;
    }
}



@media (max-width: 800px) {
    .main-content {
        padding: 0;
    }

    .nav, .nav-menu-container {
        padding: 0 10px;
    }

    .nav-menu-item {
        margin-right: 20px;
        font-size: 1rem;
    }

    .terminal-input {
        flex-wrap: nowrap;
    }

    .terminal {
        padding: 20px;
    }
}

@media (max-width: 541px) {
    .nav-menu {
        flex-wrap: wrap;
    }
    
    .nav-menu-item {
        height: 1.8rem;
    }

    .nav, .nav-menu-container {
        padding: 0 8px;
    }

    .terminal-logo {
        font-size: rem;
    }

    .terminal-logo pre {
        line-height: 0.9rem;
    }
}


@media (max-width: 430px) {

    body {
        font-size: 0.8rem;
    }

    pre {
        line-height: 1.3rem;
    }
    
    .main-content {
        padding: 0;
    }

    .nav {
        order: 1;
    }

    .terminal {
        padding: 12px;
        order: 2;
    }

    .nav-menu-container {
        order: 3;
    }

    .footer {
        order: 4;
        padding: 0 24px;
    }

    .footer-copyright {
        font-size: 0.6rem;
    }
    
    .terminal-panel {
        max-width: 100%;
    }

    .terminal-logo {
        font-size: 0.7rem;
    }

    .terminal-logo pre {
        line-height: 0.8rem;
    }

    .terminal-text pre {
        white-space: pre-wrap;
    }

    .terminal-input {
        display: flex;
        flex-wrap: wrap; /* Allow wrapping to the next line */
        align-items: start;
    }

    input[type="text"] {
        flex: 0 1;
        font-size: 0.8rem;
        line-height: 1.2rem;
    }

    .modal-content pre {
        font-size: 4px;
        line-height: 4.3px;
    }
}